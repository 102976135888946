import GATSBY_COMPILED_MDX from "/opt/build/repo/src/content/post-02.mdx";
import React from "react";
import {Link} from "gatsby";
import format from "date-fns/format";
import PageLayout from "../../components/PageLayout";
import Icon from "../../components/Icon";
var BlogPost = function BlogPost(_ref) {
  var data = _ref.data, params = _ref.params, children = _ref.children;
  var currentPost = data.allMdx.edges.filter(function (_ref2) {
    var node = _ref2.node;
    return node.frontmatter.slug === params.frontmatter__slug;
  })[0];
  var frontmatter = currentPost.node.frontmatter;
  var next = currentPost.next, previous = currentPost.previous;
  console.log("test: ", currentPost);
  return React.createElement(PageLayout, null, React.createElement("div", {
    className: "post__wrapper fwcc__wrapper"
  }, React.createElement("article", {
    className: "post fwcc"
  }, React.createElement("h1", {
    className: "post__title"
  }, frontmatter.title), React.createElement("h2", {
    className: "post__subtitle"
  }, frontmatter.subtitle), React.createElement("p", {
    className: "post__date"
  }, format(new Date(frontmatter.date), "dd LLL y")), React.createElement("div", {
    className: "post__body"
  }, children)), (next || previous) && React.createElement("section", {
    className: "post__paging fwcc"
  }, React.createElement("div", null, previous && React.createElement(Link, {
    to: "/blog/" + previous.frontmatter.slug
  }, React.createElement("span", null, React.createElement(Icon, {
    title: "Previous Arrow",
    size: 16
  }), "Previous"), React.createElement("h3", null, previous.frontmatter.title))), React.createElement("div", null, next && React.createElement(Link, {
    to: "/blog/" + next.frontmatter.slug
  }, React.createElement("span", null, "Next", React.createElement(Icon, {
    title: "Next Arrow",
    size: 16
  })), React.createElement("h3", null, next.frontmatter.title))))));
};
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var query = "1822130810";
